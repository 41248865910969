import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table
} from '@mui/material';
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";


export default({model, chartData}) => {
    const [t, i18n] = useTranslation();
    const companyScoreData = chartData?.data?.assessmentData?.companyData;
    return(
        <MainCard title={t('client_company_score_data')} contentSX={{padding: 0}}>
            <Table sx={{ p: 0 }}>
                <TableBody>
                    <TableRow key={'company_data_shortname'}>
                        <TableCell sx={{width: '20%'}}><Typography style={{fontWeight: 'bold'}}>{t('company_short_name')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.shortName}</TableCell>
                    </TableRow>
                    <TableRow key={'company_data_longname'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('company_long_name')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.longName}</TableCell>
                    </TableRow>
                    <TableRow key={'company_data_taxnumber'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('company_taxnumber')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.taxNumber}</TableCell>
                    </TableRow>
                    <TableRow key={'company_data_year'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('company_year_info')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.year}</TableCell>
                    </TableRow>
                    <TableRow key={'company_data_totalscore'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('company_totalscore')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.totalScore}</TableCell>
                    </TableRow>
                    <TableRow key={'company_data_operation_history'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('company_ophistoryscore')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.operationHistoryScore}</TableCell>
                    </TableRow>
                    <TableRow key={'company_data_profitability'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('company_profscore')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.profitabilityScore}</TableCell>
                    </TableRow>
                    <TableRow key={'company_data_cashliq'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('company_cashliqscore')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.cashLiquidityScore}</TableCell>
                    </TableRow>
                    <TableRow key={'company_data_debt'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('company_debtscore')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.debtRatioScore}</TableCell>
                    </TableRow>
                    <TableRow key={'company_data_executions'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('company_exscore')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.executionScore}</TableCell>
                    </TableRow>
                    <TableRow key={'company_data_revenue'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('company_revscore')}</Typography></TableCell>
                        <TableCell>{companyScoreData?.revenueTrendScore}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </MainCard>
    );
}