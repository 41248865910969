import { CloseOutlined, DollarCircleOutlined, InfoCircleOutlined, OrderedListOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Box, DialogTitle, Grid, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import MainCard from "components/MainCard";
import { scopes } from "config/scopes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

export default({model, clientId, bankId, label, accounts}) => {
    const dispatcher = useDispatch();
    const [t, i18n] = useTranslation();
    const [showBalances, setShowBalances] = useState(false);
    const [accountBalanceData, setAccountBalanceData] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [filter, setFilter] = useState(model.getDefaultFilter(10));
    const theme = useTheme();
    const modalStyle = {
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const canSeeBalances = model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW_ACCOUNT_BALANCES);
    const onShowBalances = () => {
        setShowBalances(false);
        setAccountBalanceData(null);
        model.fetchClientAccountBalances(dispatcher, clientId, filter).unwrap().then(res => {
            setLoaded(true);
            if(res?.success && res?.data?.balances) {
                setAccountBalanceData(res?.data?.balances);
                setShowBalances(true);
            }
            else {
                dispatch(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
              }
          });
    }
    const onCloseBalances = () => {
        setShowBalances(false);
        setAccountBalanceData(null);
    }
    return(
        <>
        <MainCard title={label} contentSX={{padding: 0}} style={{marginTop: '16px'}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('account_number')}</TableCell>
                        <TableCell sx={{width: '10%'}}>{t('type')}</TableCell>
                        <TableCell sx={{width: '20%'}}>{t('holder')}</TableCell>
                        <TableCell sx={{width: '10%'}}>{t('currency')}</TableCell>
                        <TableCell sx={{width: '10%'}}>{t('last_balance')}</TableCell>
                        <TableCell sx={{width: '5%'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accounts?.filter(i => i.accountNumber).map(i => {
                    let balance = "-";
                    let balanceType = "-";
                    let balanceDate = "-";
                    if(i.balances && i.balances.length > 0) {
                        balance = model.formatCurrency(i.balances[0].amount, i.currency);
                        balanceType = t('balance_type_'+i.balances[0].balanceType.toLowerCase());
                        if(i.balances[0].lastFetched) {
                            balanceDate = model.formatShortDate(i.balances[0].lastFetched);            
                        }
                    }
                    return (
                        <TableRow key={'account_'+i.id}>
                            <TableCell>{i.accountNumber}</TableCell>
                            <TableCell>{model.dataOrEmpty(i.bankAccountType)}</TableCell>
                            <TableCell>{model.dataOrEmpty(i.accountHolderName)}</TableCell>
                            <TableCell>{t('currency_long_'+i.currency.toLowerCase())}</TableCell>
                            <TableCell>{balance}</TableCell>
                            <TableCell>
                                {canSeeBalances && 
                                <>
                                <IconButton color="secondary" onClick={() => {
                                    setSelectedAccount(i);
                                    onShowBalances();
                                }}>
                                    <Tooltip title={t('list_balances')}>
                                        <OrderedListOutlined />
                                    </Tooltip>
                                </IconButton>
                                </>
                                }
                            </TableCell>
                        </TableRow>
                    )
                    })
                    }
                </TableBody>
            </Table>
        </MainCard>
        <Modal open={showBalances} onClose={onCloseBalances}>
            <Box sx={modalStyle}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ borderBottom: `0px solid ${theme.palette.divider}` }}>
                    <Grid item>
                        <DialogTitle>{t('balances')}</DialogTitle>
                    </Grid>
                    <Grid item sx={{ mr: 1.5 }}>
                    <IconButton color="secondary" onClick={onCloseBalances}>
                        <CloseOutlined />
                    </IconButton>
                    </Grid>
                </Grid>
                <MainCard border={false} boxShadow>
                    {accountBalanceData && 
                    <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('id')}</TableCell>
                            <TableCell>{t('date')}</TableCell>
                            <TableCell>{t('last_balance')}</TableCell>
                            <TableCell>{t('type')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {accountBalanceData.map((i, k) => {
                        return(
                            <TableRow key={'balance_'+k}>
                                <TableCell>{i.id}</TableCell>
                                <TableCell sx={{width: '40%'}}>{model.formatShortDate(i.balanceDate)}</TableCell>
                                <TableCell>{model.formatCurrency(i.amount, selectedAccount.currency)}</TableCell>
                                <TableCell>{t('balance_type_'+i.balanceType.toLowerCase())}</TableCell>
                            </TableRow>
                        );
                    })}
                    </TableBody>
                    </Table>
                    }
                </MainCard>
            </Box>
        </Modal>
        </>
    );
}