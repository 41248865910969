// material-ui
import {
  Button,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
  } from '@mui/material';

// assets
import { BackwardOutlined, PrinterOutlined, RedoOutlined, } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPeriodType } from 'store/reducers/client';
import ClientDetailsViewModel from '../model/ClientDetailsViewModel';
import useAuth from 'hooks/useAuth';
import ClientPersonalScore from './score/ClientPersonalScore';
import ClientBusinessScore from './score/ClientBusinessScore';
import ClientTransactionList from './assessment/ClientTransactionList';
import ClientCommonTransactionList from './assessment/ClientCommonTransactionList';
import ClientPersonalIncomeBox from './icome/ClientPersonalIncomeBox';
import ClientAssessmentStatistics from './assessment/ClientAssessmentStatistics';
import ClientRiskScoreBox from './assessment/ClientRiskScoreBox';
import ClientInflowStatistics from './assessment/ClientInflowStatistics';
import ClientOutflowStatistics from './assessment/ClientOutflowStatistics';
import ClientPeriodTotals from './assessment/ClientPeriodTotals';
import ClientFullTotals from './assessment/ClientFullTotals';
import ClientIncomeAnalysis from './icome/ClientIncomeAnalysis';
import InfoBoxWithButton from '../components/InfoBoxWithButton';
import ClientCertificate from './assessment/ClientCertificate';
import { useTranslation } from 'react-i18next';
import MainCard from 'components/MainCard';
import { config as apiConfig, selectedProfile } from '../../../config/config';
import { StringUtils } from 'utils/StringUtils';
import ClientAccountsBox from './assessment/ClientAccountsBox';
import { scopes, hasScope } from '../../../config/scopes';
import ClientFraudBox from './assessment/ClientFraudBox';
import ClientFraudScore from './score/ClientFraudScore';
import ClientCompanyScoreData from './assessment/ClientCompanyScoreData';
import ClientPersonalRiskScoreData from './assessment/ClientPersonalRiskScoreData';
import ClientSocioScoreData from './assessment/ClientSocioScoreData';

export default({clientId, period, assessmentId, setSelectedAssessment}) => {
    const dispatcher = useDispatch();
    const context = useAuth();
    const [t, i18n] = useTranslation();

    const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
    const consentData = data?.data?.consentData;
    const model = new ClientDetailsViewModel(null, context.user);
    const personal = data?.data?.personal;
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
      if(data) {
        setLoaded(false);
        model?.fetchClientData(dispatcher, clientId, period, assessmentId).unwrap().then(res => {
          setLoaded(true);
        });
      }
    }, [period, data, dispatcher, clientId, assessmentId]);
    if(!data) {
      return "";
    }
    const chartData = useSelector(state => state.client.clientChartData);
    if(chartData) {
      model.setClientData(chartData.data?.data);
    }
    if(!chartData) {
      return "";
    }
    const changePeriod = (e) => {
      dispatcher(selectPeriodType(e.target.value));
    }
    const onRefresh = () => {
      
    }
    const hasValidData = true;
    const hasValidAssessmentData = chartData?.data?.containsDataDetails;
    //Client type
    const clientType = data?.data?.personal.clientType;
    //List data
    const filteredCredits = model.getFilteredCredits();
    const filteredDebits = model.getFilteredDebits();
    const combinedTop10Credits = model.getCombinedTop10Credits();
    const combinedTop10Debits = model.getCombinedTop10Debits();
    const commonDebits = model.getCommonDebits();
    const commonCredits = model.getCommonCredits();
    const navTransactions = model.getNavTransactions();
    const badNavTransactions = model.getBadNavTransactions();
    const accounts = chartData?.data?.accounts;
    //Sub score data
    const companyScoreData = chartData?.data?.assessmentData?.companyData;
    const personalRiskData = chartData?.data?.assessmentData?.personalRiskData;
    const socioData = chartData?.data?.assessmentData?.socioData;

    const showHistoricalInfo = assessmentId && data?.data?.lastAssessment.id != assessmentId;

    const showNewestAssessment = () => {
      setSelectedAssessment(null);
    }

    const canViewAll = model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW_ALL);
    const canSeeSummaryStats = canViewAll || model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW_SUMMARY_STATS);
    const canSeeIncomeData = canViewAll || model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW_INCOME);
    const canSeeFraudData = canViewAll || model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW_FRAUD);
    const canSeeAccountData = canViewAll || model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW_ACCOUNTS);
    const canSeeStatData = canViewAll || model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW_STATS);
    const canSeeTransactionData = canViewAll || model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW_TRANSACTIONS);
    const canSeeCertificateData = canViewAll || model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW_CERTIFICATE);
    const canExportData = canViewAll || model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW_EXPORT);
    const canSeeScoringData = model.hasScope(scopes.SCOPE_MITIGATE_SCORE_DETAILS);
    
    const flexSize = (clientType == "PERSONAL" && canSeeIncomeData) ? 4 : 8;

    const onPrintPdf = () => {
      let clientAssessmentId = chartData?.data?.assessmentData.id;
      const title = t('risk_assessment');
      const displayName = data?.data?.personal?.displayName;
      const clientTypeName = StringUtils.clientTypeText(t, clientType);
      const clientEmail= data?.data?.personal?.email;
      let clientExternalId = data?.data?.personal?.externalId;
      if(!clientExternalId) {
        clientExternalId = "-";
      }
      const score = chartData?.data?.assessmentData?.normalizedScore;
      const riskRating = StringUtils.getRiskRatingString(t, chartData?.data?.assessmentData?.scoreClass);
      const dates = model.formatShortDate(chartData?.data?.data?.periodFrom)+' - '+model.formatShortDate(chartData?.data?.data?.periodTo);
      const paymentData = model.formatCurrency(chartData.data.data.maxAllowedMonthlyPayment, data?.data?.personal?.currency);
      const createdAt = model.formatShortDate(chartData?.data?.assessmentData?.createdAt);
      const openedWindow = prepareOpenWindow();
      openedWindow.document.write('<div id="printContainer">');
      openedWindow.document.write('<h1>'+title+'</h1><br/>');
      openedWindow.document.write('<h3>'+displayName+'</h3>');
      openedWindow.document.write('<h4>'+clientTypeName+'</h3>');
      openedWindow.document.write('<h4>'+clientEmail+'</h3><hr/>');
      openedWindow.document.write('<p><b>'+t('client_external_id')+':</b> '+clientExternalId+'</p>');
      openedWindow.document.write('<p><b>'+t('assessment_id')+':</b> '+clientAssessmentId+'</p><hr/>');
      openedWindow.document.write('<p><b>'+t('score')+':</b> '+score+'/100</p>');
      openedWindow.document.write('<p><b>'+t('risk')+':</b> '+riskRating+'</p>');
      openedWindow.document.write('<p><b>'+t('period')+':</b> '+dates+'</p>');
      openedWindow.document.write('<p><b>'+t('monthly_payment_title')+':</b> '+paymentData+'</p><hr/>');
      openedWindow.document.write('<p><b>'+t('created')+':</b> '+createdAt+'</p><br/>');
      openedWindow.document.write('</div>');
      openedWindow.document.write('<input type="button" value="'+t('print')+'" onClick="onPrint()" />');
      openedWindow.document.write('<link rel="stylesheet" type="text/css" href="print.css">');
      openedWindow.document.write('<script src="https://printjs-4de6.kxcdn.com/print.min.js"></script>');
      openedWindow.document.write('<script>function onPrint() {printJS(\'printContainer\', \'html\')}</script>');
      openedWindow.document.title = title;
      openedWindow.document.body.style.backgroundColor = 'white';
    }

    const prepareOpenWindow = () => {
      var baseUrl = apiConfig[selectedProfile].PUBLIC_API_BASE_URL;
      const w = 1024;
      const h = 768;
      const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
      const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
      return window.open(baseUrl+'/mitigate/public/loading', '_blank', `toolbar=yes, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
  }


    return (
      <Grid container spacing={3}>
        {showHistoricalInfo && 
        <Grid item xs={12}>
          <InfoBoxWithButton 
            title={t('assessment_historical')}
            button={<BackwardOutlined />}
            buttonEnabled={true}
            buttonHandler={showNewestAssessment}
            buttonText={t('show_newest')} />
        </Grid>
        }
        <Grid id="containerTop" item xs={12}>
          <Grid container spacing={3}>
            {hasValidData && 
            <>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <ClientRiskScoreBox model={model} clientType={clientType} chartData={chartData?.data} personalData={personal} />
              </Grid>
              {canSeeSummaryStats && 
              <Grid item xs={12} sm={flexSize} md={flexSize} lg={flexSize}>
                <ClientAssessmentStatistics model={model} chartData={chartData?.data} assessmentData={chartData?.data?.assessmentData} personalData={personal} />
              </Grid>
              }
              {clientType == "PERSONAL" && canSeeIncomeData && 
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <ClientPersonalIncomeBox model={model} chartData={chartData?.data} personalData={personal} />
                </Grid>
              }
            </>
            }
          </Grid>
        </Grid>
        <Grid id="containerBottom" item xs={12}>
        {consentData && !consentData.lastRun && consentData.status == "APPROVED" && 
          <Grid id="containerInfo" container spacing={3}>
            <Grid item xs={12}>
                <InfoBoxWithButton 
                  title={t('bank_access_live')}
                  button={<RedoOutlined />}
                  buttonEnabled={loaded}
                  buttonHandler={onRefresh}
                  buttonText={t('refresh')} />
              </Grid>
          </Grid>
          }
          {hasValidData && 
          <>
            {hasValidAssessmentData && 
            <Grid id="containerFraud" container spacing={3} sx={{mt: 0}}>
              {canSeeFraudData && 
              <Grid item xs={12}>
                <ClientFraudBox model={model} chartData={chartData} />
              </Grid>
              }
              {canSeeAccountData && 
              <Grid item xs={12}>
                <ClientAccountsBox model={model} chartData={chartData} />
              </Grid>
              }
            </Grid>
            }
            {chartData?.data?.assessmentData?.certificateId && canSeeCertificateData && 
            <Grid id="containerCertificate" container spacing={3} sx={{mt: 0}}>
              <Grid item xs={12}>
                <ClientCertificate model={model} chartData={chartData} />
              </Grid>
            </Grid>
            }
            {companyScoreData && 
            <Grid id="companyScoreDataContainer" container spacing={3} sx={{mt: 0}}>
              <Grid item xs={12}>
                <ClientCompanyScoreData model={model} chartData={chartData} />
              </Grid>
            </Grid>
            }
            {personalRiskData && 
            <Grid id="personalRiskScoreDataContainer" container spacing={3} sx={{mt: 0}}>
              <Grid item xs={12}>
                <ClientPersonalRiskScoreData model={model} chartData={chartData} />
              </Grid>
            </Grid>
            }
            {socioData && 
            <Grid id="socioScoreDataContainer" container spacing={3} sx={{mt: 0}}>
              <Grid item xs={12}>
                <ClientSocioScoreData model={model} chartData={chartData} />
              </Grid>
            </Grid>
            }
            {hasValidAssessmentData && canExportData && 
              <Grid container spacing={3} sx={{mt: 0}}>
                <Grid item xs={12}>
                  <MainCard title="Print PDF" contentSX={{padding: 0}}>
                    <Button onClick={onPrintPdf} sx={{marginTop: 2, marginLeft: 2}} variant="shadow" color="info" endIcon={<PrinterOutlined />}>Print</Button>  
                  </MainCard>
                </Grid>
              </Grid>
            }
            {hasValidData && canSeeScoringData && 
            <Grid id="scoringContainer" container spacing={3} sx={{mt: 0}}>
              {clientType == "PERSONAL" &&
                <>
                <Grid item xs={12}>
                    <ClientPersonalScore model={model} chartData={chartData?.data} personalData={personal} />
                </Grid>
                <Grid item xs={12}>
                  <ClientFraudScore model={model} chartData={chartData?.data} personalData={personal} />
                </Grid>
                </>
              }
              {clientType == "BUSINESS" && 
                <Grid item xs={12}>
                  <ClientBusinessScore model={model} chartData={chartData?.data} personalData={personal} />
                </Grid>
              }
              {hasValidAssessmentData && clientType == "PERSONAL" &&
              <Grid item xs={12}>
                <ClientIncomeAnalysis model={model} chartData={chartData?.data} personalData={personal} />
              </Grid>
              }
            </Grid>
            }
            {hasValidAssessmentData && canSeeStatData && 
            <>
              <Grid id="fullTotalsContainer" container spacing={3} sx={{mt: 0}}>
                <Grid item xs={12}>
                  <ClientFullTotals model={model} chartData={chartData?.data} personalData={personal} />
                </Grid>
              </Grid>
              <Grid id="containerControls" container spacing={3} justifyContent="flex-end" sx={{mt: 0}}>
                  <Grid item>
                    <ToggleButtonGroup exclusive size="small" value={period} onChange={changePeriod}>
                      <ToggleButton disabled={period === 'MONTHLY'} value="MONTHLY" sx={{ px: 2, py: 0.5 }}>
                        {t('monthly')}
                      </ToggleButton>
                      <ToggleButton disabled={period === 'DAY30'} value="DAY30" sx={{ px: 2, py: 0.5 }}>
                        {t('thirty_days')}
                      </ToggleButton>
                      <ToggleButton disabled={period === 'WEEKLY'} value="WEEKLY" sx={{ px: 2, py: 0.5 }}>
                      {t('weekly')}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
              </Grid>
              
              <Grid id="containerPeriodTotals" container spacing={3} sx={{mt: 0}}>
                <Grid item xs={12}>
                    <ClientPeriodTotals model={model} personalData={personal} />
                  </Grid>
                </Grid>
            </>
            }
            {hasValidAssessmentData && 
            <Grid id="containerStatistics" container spacing={3} sx={{mt: 0}}>

              {canSeeStatData && 
              <Grid item xs={12}>
                <ClientOutflowStatistics model={model} period={period} loaded={loaded} />
              </Grid>
              }

              {canSeeTransactionData && 
              <Grid item xs={12}>
                <ClientTransactionList id="debit_transactions_top_10" title={t('outflow_top_amount')} model={model} transactions={combinedTop10Debits} />
              </Grid>
              }

              {canSeeTransactionData && 
              <Grid item xs={12}>
                <ClientCommonTransactionList id="debit_transactions_common" title={t('outflow_top_common')} model={model} transactions={commonDebits} personalData={personal} />
              </Grid>
              }

              {canSeeTransactionData && 
              <Grid item xs={12}>
                <ClientTransactionList id="risky_debit" title={t('outflow_risky')} model={model} transactions={filteredDebits} />
              </Grid>
              }

              {clientType == "BUSINESS" && canSeeTransactionData && 
              <>
                <Grid item xs={12}>
                  <ClientTransactionList id="nav_transactions" title={t('nav_transactions')} model={model} transactions={navTransactions} />
                </Grid>
                <Grid item xs={12}>
                  <ClientTransactionList id="bad_nav_transactions" title={t('bad_nav_transactions')} model={model} transactions={badNavTransactions} />
                </Grid>
              </>
              }

              {canSeeStatData && 
              <Grid item xs={12}>
                <ClientInflowStatistics model={model} period={period} loaded={loaded} />
              </Grid>
              }

              {canSeeTransactionData && 
              <Grid item xs={12}>
                <ClientTransactionList id="credit_transaction_top_10" title={t('inflow_top_amount')} model={model} transactions={combinedTop10Credits} />
              </Grid>
              }

              {canSeeTransactionData && 
              <Grid item xs={12}>
                <ClientCommonTransactionList id="credit_transactions_common" title={t('inflow_top_common')} model={model} transactions={commonCredits} personalData={personal} />
              </Grid>
              }

              {canSeeTransactionData && 
              <Grid item xs={12}>
                <ClientTransactionList id="risky_credit_transactions" title={t('inflow_risky')} model={model} transactions={filteredCredits} />
              </Grid>
              }

            </Grid>
            }
          </>
          }
        </Grid>
      </Grid>
    );
}