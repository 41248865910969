import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table
} from '@mui/material';
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";


export default({model, chartData}) => {
    const [t, i18n] = useTranslation();
    const personalRiskData = chartData?.data?.assessmentData?.personalRiskData;
    return(
        <MainCard title={t('client_personal_risk_score_data')} contentSX={{padding: 0}}>
            <Table sx={{ p: 0 }}>
                <TableBody>
                    <TableRow key={'personal_risk_total_score'}>
                        <TableCell sx={{width: '20%'}}><Typography style={{fontWeight: 'bold'}}>{t('personal_risk_total_score')}</Typography></TableCell>
                        <TableCell>{personalRiskData?.totalScore}</TableCell>
                    </TableRow>
                    <TableRow key={'personal_risk_identity_score'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('personal_risk_identity_score')}</Typography></TableCell>
                        <TableCell>{personalRiskData?.identityScore}</TableCell>
                    </TableRow>
                    <TableRow key={'personal_risk_transaction_score'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('personal_risk_transaction_score')}</Typography></TableCell>
                        <TableCell>{personalRiskData?.transactionRiskScore}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </MainCard>
    );
}