export default() => {
    return {
        login: 'Login',
        logout: 'Logout',
        forgot_password: 'Forgot password',
        back_to_login: 'Back to login',
        send_password_email: 'Send Password Reset Email',
        password_reset_success: 'Check mail for reset password link',
        dashboard_title: 'Statosfera Dashboard',
        row_per_page: 'Row per page',
        search: 'Search',
        no_data: 'No data available',
        menu_item_main_menu: 'Menu',
        menu_item_home: 'Home',
        menu_item_settings: 'Settings',
        menu_item_access: 'Access',
        menu_item_recash: 'Recash',
        menu_item_recash_dashboard: 'Dashboard',
        menu_item_recash_settings: 'Settings',
        menu_item_recash_transactions: 'Transactions',
        menu_item_mitigate: 'Mitigate',
        menu_item_mitigate_dashboard: 'Dashboard',
        menu_item_mitigate_clients: 'Clients',
        menu_item_profound: 'Profound',
        menu_item_profound_dashboard: 'Dashboard',
        menu_item_profound_users: 'My Users',
        save: 'Save',
        cancel: 'Cancel',
        add: 'Add',
        refresh: 'Refresh',
        group: 'Group',
        email: 'E-mail',
        name: 'Name',
        phone: 'Phone',
        created: 'Created',
        updated: 'Updated',
        status: 'Status',
        banned: 'Banned',
        verified: 'Verified',
        active: 'Active',
        actions: 'Actions',
        unblock: 'Unblock',
        block: 'Block',
        view: 'View',
        edit: 'Edit',
        delete: 'Delete',
        groups: 'Groups',
        add_user: 'Add user',
        access: 'Access',
        field_is_required: 'This field is required',
        email_is_required: 'E-mail is required',
        must_be_valid_email: 'Must be a valid e-mail address',
        last_name_is_required: 'Last name is required',
        first_name_is_required: 'First name is required',
        legal_name_is_required: 'Legal name is required',
        phone_is_required: 'Phone is required',
        gender_is_required: 'Gender is required',
        zip_is_required: 'Zip code is required',
        city_is_required: 'City is required',
        street_is_required: 'Street is required',
        external_id_is_required: 'External ID is required',
        language: 'Language',
        language_is_required: 'Language is required',
        monthly_fixed_deduction_is_required: 'Monthly fixed deduction is required',
        must_be_a_number: 'Must be a number',
        must_be_min_value: 'Must be at least {{min}}',
        must_be_max_value: 'Must be at most {{max}}',
        scope_is_required: 'Access scope is required',
        must_be_min_8_characters: 'Password must be minimum 8 characters long',
        password_complexity_error: 'Password must contain at least 1 uppercase letter, 1 lowercase letter, and one number',
        password_is_required: 'Password is required',
        password_match_error: 'The passwords must match',
        password_again_is_required: 'Password again is required',
        phone_format_error: 'Phone number must follow the format (+36304047324) and must be at least 10 characters long',
        user_edited: 'User edited successfully',
        user_added: 'New user added successfully',
        edit_user: 'Edit user',
        new_user: 'New user',
        enter_email_address: 'Enter E-mail address',
        password: 'Password',
        enter_password: 'Enter Password',
        password_again: 'Password again',
        enter_password_again: 'Enter the password again',
        personal_details: 'Personal details',
        first_name: 'First name',
        enter_first_name: 'Enter first name',
        last_name: 'Last name',
        enter_last_name: 'Enter last name',
        enter_phone: 'Enter Phone number (format: +36304523912)',
        access_scope: 'Access scope',
        other_settings: 'Other settings',
        lang_hu: 'Hungarian',
        lang_cz: 'Czech',
        lang_hr: 'Croatian',
        lang_en: 'English',
        block_user: 'Block user',
        unblock_user: 'Unblock user',
        user_blocked: 'User blocked',
        user_unblocked: 'User unblocked',
        block_confirm: 'Are you sure you want to block the user? This prevents logging in for the user but it is reversible.',
        unblock_confirm: 'Are you sure you want to unblock the user?',
        delete_user: 'Delete user',
        delete_user_confirm: 'Are you sure you want to delete the user? This action is non-reversible.',
        user_deleted: 'User deleted',
        products: 'Products',
        access_rights: 'Access rights',
        add_group: 'Add group',
        new_group: 'New group',
        edit_group: 'Edit group',
        enter_group_name: 'Enter group name',
        group_edited: 'Group edited',
        group_added: 'Group added',
        group_name_length_error: 'Name must be at least 4 characters long',
        group_name_is_required: 'Group name is required',
        delete_group: 'Delete group',
        delete_group_confirm: 'Are you sure you want to delete the group? This action is non-reversible.',
        group_deleted: 'Group deleted',
        members: 'Members',
        client_risk_level_excellent: 'Excellent',
        client_risk_level_safe: 'Good',
        client_risk_level_medium: 'Fair',
        client_risk_level_low: 'Poor',
        client_risk_level_risky: 'Very poor',
        clients: 'Clients',
        add_client: 'Add client',
        closed: 'Closed',
        error: 'Error',
        new: 'New',
        client_type: 'Client type',
        last_score: 'Last score',
        client_type_is_required: 'Client type is required',
        monthly_payment_is_required: 'Monthly payment is required',
        new_client: 'New Client',
        edit_client: 'Edit Client',
        client_edited: 'Client edited',
        client_added: 'Client added',
        business_entity_details: 'Business entity details',
        company_data_details: 'Company data',
        payment_details: 'Payment details',
        contact_data: 'Contact data',
        address: 'Address',
        birth_date: 'Birth date',
        client_type_personal: 'Personal',
        client_type_business: 'Business',
        legal_name: 'Legal name',
        enter_legal_name: 'Enter legal name',
        tax_number: 'Tax number',
        enter_tax_number: 'Enter tax number',
        enter_phone_number: 'Enter phone number (format: +36304523912)',
        social_data_personal: 'Personal information',
        gender: 'Gender',
        gender_male: 'Male',
        gender_female: 'Female',
        gender_other: 'Other',
        citizenship: 'Primary citizenship',
        social_data_income: 'Income information',
        taxresidency: 'Tax residency country',
        net_monthly_income: 'Monthly income (net)',
        enter_net_monthly_income: 'Enter net monthly income',
        income_currency: 'Currency',
        social_data_other: 'Other personal information',
        education_level: 'Education level',
        education_level_basic: 'Basic',
        education_level_apprenticeship: 'Apprenticeship',
        education_level_secondary: 'Secondary',
        education_level_college: 'College',
        education_level_university: 'University',
        family_status: 'Family status',
        family_status_single: 'Single',
        family_status_married: 'Married',
        family_status_partnership: 'Partnership',
        family_status_divorced: 'Divorced',
        family_status_widowed: 'Widowed',
        householdsize: 'Household size',
        enter_householdsize: 'Enter household size (number of people)',
        number_of_dependents: 'Dependents',
        enter_number_of_dependents: 'Enter number of dependents (number of people)',
        address_country: 'Country',
        zip_code: 'Zip code',
        enter_zip_code: 'Enter ZIP code',
        city: 'City',
        enter_city: 'Enter city',
        street: 'Street',
        enter_street: 'Enter street address',
        monthly_payment: 'Monthly payment ({{currency}})',
        monthly_payment_title: 'Monthly payment',
        enter_monthly_payment: 'Enter {{currency}} monthly payment value', 
        monthly_fixed_deduction: 'Monthly fixed deduction ({{currency}})',
        enter_monthly_deduction: 'Enter {{currency}} monthly fixed deduction value',
        monthly_fixed_addition: 'Monthly fixed payment ({{currency}})',
        enter_monthly_addition: 'Monthly fixed payment ({{currency}})',
        monthly_fixed_addition_is_required: 'Monthly fixed payment is required',
        external_id: 'External ID',
        enter_external_id: 'Enter external ID (sales, ERP, SAP etc. ID)',
        delete_client: 'Delete Client',
        delete_client_confirm: 'Are you sure you want to delete the client? This action is non-reversible.',
        client_deleted: 'Client deleted',
        bank_access: 'Bank access',
        risk_rating: 'Risk rating',
        details: 'Details',
        date_of_birth: 'Date of birth',
        financial_details: 'Financial details',
        comment: 'Comment',
        client_profile: 'Client profile',
        risk_assessment: 'Risk assessment',
        assessment_history: 'Assessment history',
        bank_accounts: 'Bank accounts',
        transactions: 'Transactions',
        communications: 'Communications',
        events: 'Events',
        logs: 'Logs',
        link_regenerated: 'Link re-generated successfully',
        link_generated: 'Link generated successfully',
        link_generation_error: 'There was an error during generation',
        data_access_link: 'Data access link',
        data_access_link_desc: 'Please generate a new link to start the process for the client.',
        start: 'Start',
        generate_access_link: 'Generate client portal access link',
        send_link_to: 'Send link to...',
        method_of_communication: 'Method of communication',
        email_to_address: 'Send e-mail to {{emailAddress}}',
        sms_to_number: 'Send SMS to {{number}}',
        copy_to_clipboard: 'Copy to clipboard',
        assessment_historical: 'This assessment is historical',
        show_newest: 'Show newest',
        bank_access_live: 'Bank access is live but data is not available yet',
        monthly: 'Monthly',
        thirty_days: '30 Days',
        weekly: 'Weekly',
        outflow_top_amount: 'Outflow transactions (top 10 amount)',
        outflow_top_common: 'Outflow transactions (top 10 common)',
        outflow_risky: 'Risky transactions (outflow)',
        nav_transactions: 'NAV transactions',
        bad_nav_transactions: 'Bad NAV transactions',
        inflow_top_amount: 'Inflow transactions (top 10 amount)',
        inflow_top_common: 'Inflow transactions (top 10 common)',
        inflow_risky: 'Risky transactions (Inflow)',
        certificate: 'Certificate',
        from: 'From',
        to: 'To',
        score: 'Score',
        date: 'Date',
        show_assessment: 'Show assessment',
        request_data: 'Request data',
        risk_scoring: 'Risk scoring',
        risk: 'Risk',
        bank_access_info: 'Bank access information',
        go_to_bank_access: 'Please go to the Bank Access tab and generate a link for the client.',
        client_link_was_generated: 'Client link was generated. Please wait until the client finishes the consent process.',
        go: 'Go',
        title: 'Title',
        type: 'Type',
        subtype: 'Sub-type',
        result: 'Result',
        sent_at: 'Sent at',
        data: 'Data',
        session_id: 'Session ID',
        started: 'Started',
        finished: 'Finished',
        show_logs: 'Show logs',
        transaction_info: 'Transaction info',
        category: 'Category',
        amount: 'Amount',
        account_number: 'Account number',
        holder: 'Account holder name',
        last_balance: 'Last balance',
        currency: 'Currency',
        list_balances: 'List balances',
        balances: 'Balances',
        period_from: 'Period from',
        period_to: 'Period to',
        date_fetched: 'Date fetched',
        total_transactions: 'Total transactions',
        net_flow: 'Net flow',
        client_certificate: 'Client certificate',
        count: 'Count',
        sum_amount: 'Sum amount',
        average: 'Average',
        totals_period: 'Totals for the whole period',
        net_total: 'Net total',
        outflow: 'Outflow',
        inflow: 'Inflow',
        outflow_count: 'Outflow count',
        inflow_count: 'Inflow count',
        inflow_stats: 'Inflow statistics',
        total: 'Total',
        median: 'Median',
        period_totals: 'Period totals',
        period: 'Period',
        scoring_desc_title: 'Scoring description',
        scoring_desc_text: 'Lorem ipsum',
        expiring_at: 'Expiring in: {{date}}',
        link_expired: 'Link expired, please restart the process',
        client_link: 'Client link',
        regenerate_link: 'Regenerate link',
        bank_consents: 'Bank access consents',
        bank: 'Bank',
        last_run: 'Last run',
        last_fail: 'Last fail',
        banks: 'Banks',
        logo: 'Logo',
        api_status: 'API Status',
        id: 'ID',
        log: 'Log',
        session_logs: 'Session logs',
        income_analysis: 'Income analysis',
        income_type: 'Income type',
        monthly_income: 'Monthly income',
        total_income: 'Total income',
        income_green: 'Green',
        income_yellow: 'Yellow',
        income_red: 'Red',
        income_under_limit: 'Under limit',
        transaction_category_debit: 'Debit',
        transaction_category_credit: 'Credit',
        transaction_category_unknown: 'Unknown',
        transaction_type_transfer: 'Transfer',
        transaction_type_card: 'Card payment',
        transaction_type_internal: 'Internal',
        transaction_type_refund: 'Refund',
        transaction_type_atm: 'ATM use',
        transaction_type_topup: 'Topup by card',
        transaction_type_exchange: 'Exchange',
        transaction_type_other: 'Other',
        transaction_type_payment: 'Other payment',
        transaction_type_credit_payment: 'Credit payment',
        transaction_type_credit_misc: 'Credit other',
        transaction_type_investment: 'Investment',
        transaction_type_insurance: 'Insurance',
        transaction_type_unknown: 'Unknown',
        currency_huf: 'Ft',
        currency_long_huf: 'Forint',
        currency_eur: 'EUR',
        currency_long_eur: 'Euro',
        currency_usd: 'USD',
        currency_long_usd: 'US Dollar',
        currency_gbp: 'GBP',
        currency_long_gbp: 'Pounds',
        currency_czk: 'CZK',
        currency_long_czk: 'Czech Krone',
        balance_type_interim_available: 'Interim available',
        balance_type_other: 'Other',
        information: 'Information',
        status_new: 'New',
        status_new_desc: 'New client without data',
        status_social: 'Socio score',
        status_social_desc: 'Client has basic score calculated based on socio-economic data',
        status_link_generated: 'Link sent',
        status_link_generated_desc: 'The portal access link for the bank access consent process has been generated and sent to the client',
        status_portal_opened: 'Client portal',
        status_portal_opened_desc: 'The client has visited the consent portal but not yet finished with the bank access consent process',
        status_consent_added: 'Consent added',
        status_consent_added_desc: 'The client has successfully added a bank access consent, but the risk calculation process was not finished yet',
        status_in_progress: 'In progress',
        status_in_progress_desc: 'The risk calculation process has finished and there is data available',
        status_error: 'Error',
        status_error_desc: 'There has been an error during the process',
        status_finished: 'Finished',
        status_finished_desc: 'Client process was finished',
        status_approved: 'Approved',
        status_approved_desc: 'Client was accepted for the company per their risk assessment',
        status_rejected: 'Rejected',
        status_rejected_desc: 'Client was rejected for the company per their risk assessment',
        status_closed: 'Closed',
        status_closed_desc: 'Client was closed',
        consent_status_no_data: 'No data',
        consent_status_in_progress: 'In progress',
        consent_status_ok: 'Valid',
        client_external_id: 'Client external ID',
        assessment_id: 'Internal assessment ID',
        print: 'Print',
        access_settings: 'Access settings',
        request_data_desc: 'You can request local data once every hour and remote API data every 6 hours.',
        request_data_type: 'Data request type',
        request_data_type_local: 'New assessment from local data',
        request_data_type_local_desc: 'Create a new assessment based on the data already saved in our system. The new assessment will be based on the client\'s current settings (i.e. monthly payment etc.). This process can take 1-5 seconds depending on the amount of data.',
        request_data_type_api: 'New assessment from banking data',
        request_data_type_api_desc: 'Create a new assessment based on data fetched from the banks the client has given consents to. The fetched data will cover the client\'s banking data from the last 90 days. The new assessment will be based on the client\'s current settings (i.e. monthly payment etc.). This process can take up to 30 seconds depending on the number of active consents the client has and the amount of data.',
        data_request_success: 'Data request saved, refreshing.',
        data_request_success_api: 'Data request saved, please wait a minute until the process finishes and refresh the page',
        all_users: 'All users',
        own_users: 'Own users',
        all_clients: 'All clients',
        own_clients: 'Own clients',
        welcome: 'Hello',
        logged_in_as: 'Logged in as',
        system_name: 'System',
        system_version: 'Version',
        mitigate_statistics: 'Mitigate statistics',
        new_clients: 'New clients',
        processed_clients: 'Processed clients',
        last_client_added: 'Last client',
        status_required: 'Error',
        status_auth_error: 'Error',
        status_pending: 'Pending',
        bank_scans: 'Manual scans',
        status_new_scan: 'New',
        status_uploaded_scan: 'Uploaded',
        status_converted_scan: 'Converted',
        status_processed_scan: 'Processed',
        status_deleted_scan: 'Deleted',
        label: 'Label',
        add_scan: 'Add scan',
        delete_scan: 'Delete scan',
        delete_scan_confirm: 'Are you sure you want to delete this scan?<br/><br/><b>WARNING:</b> This will delete the scan and the transactions permanently. This action is non-reversible',
        scan_deleted: 'Scan deleted',
        scan_details: 'Scan details',
        scan_basic_data: 'Basic data',
        scan_files: 'Scan files',
        file_name: 'File name',
        file_size: 'File size',
        scan_upload_status: 'Upload status',
        scan_conversion_status: 'Conversion status',
        scan_processed_status: 'Processing status',
        scan_processed_transactions: 'Processed transactions',
        status_scan_uploaded_ok: 'Uploaded',
        status_scan_uploaded_error: 'Upload error',
        status_scan_converted_none: 'None',
        status_scan_converted_ok: 'Converted',
        status_scan_converted_error: 'Conversion error',
        status_scan_converted_warn: 'Converted (with warnings)',
        status_scan_processed_none: 'None',
        status_scan_processed_ok: 'Processed',
        status_scan_processed_error: 'Processing error',
        status_scan_processed_warn: 'Processed (with warnings)',
        new_scan: 'New scan',
        scan_settings: 'Scan settings',
        enter_scan_label: 'Label',
        scan_file: 'File',
        enter_scan_file: 'File',
        label_is_required: 'Label is required',
        bankid_is_required: 'Bank is required',
        file_is_required: 'File is required',
        file_is_not_valid_type: 'Invalid file type. Only PDF files are allowed',
        file_too_large: 'The file is too large. Maximum allowed size is 50MB.',
        scan_added: 'Scan added. Please wait until the processing is finished in the background. Can take up to a minute.',
        scan_data_status: 'Scan upload status',
        scan_id: 'Scan ID',
        close: 'Close',
        number_of_errors: 'Number of errors',
        number_of_warnings: 'Number of warnings',
        show_errors: 'List of errors',
        show_warnings: 'List of warnings',
        scan_logs: 'Scan logs',
        scan_statement_id: 'Statement ID',
        scan_statement_created_at: 'Statement date',
        scan_statement_from: 'Statement from',
        scan_statement_to: 'Statement to',
        export_clients: 'Export',
        export_link_created: 'Link created successfully',
        download: 'Download file',
        link_valid_until: 'Download link valid until',
        loading: 'Loading',
        statistics: 'Statistics',
        bank_tutorial: 'Guide',
        client_already_exists_with_email: 'A client with this e-mail address already exists',
        client_status_is_required: 'Status is required',
        change_client_status: 'Change client status',
        enter_admin_comment: 'Enter administrator comment (maximum 2048 characters)',
        admin_comment: 'Comment',
        status_changed: 'Client status changed',
        status_client_reason_did_not_want_consent: 'Did not want to provide consent',
        status_client_reason_could_not_give_consent_missing_login_data: 'Could not provide consent (did not know the login data)',
        status_client_reason_could_not_give_consent_bank_error: 'Could not provide consent (there was an error)',
        status_client_reason_did_not_want_contract: 'Abstain from contract',
        status_client_reason_other: 'Other',
        change_status: 'Status',
        status_types_all: 'All',
        status_types_active: 'Active client',
        status_types_inactive: 'Inactive client',
        status_types_new: 'New client',
        status_types_link_generated: 'Link sent',
        status_types_portal_opened: 'Portal opened',
        status_types_consent_added: 'Bank consent added',
        status_types_in_progress: 'In progress',
        status_types_approved: 'Approved',
        status_types_rejected: 'Rejected',
        status_types_error: 'Error',
        status_types_finished: 'Finished',
        status_types_closed: 'Closed',
        client_administrator: 'Client manager',
        user_profile: 'My profile',
        profile_basic: 'Profile',
        profile_personal: 'Edit',
        profile_change_password: 'Change password',
        profile_settings: 'Settings',
        language_hu: 'Hungarian',
        language_en: 'English',
        profile_updated: 'Profile updated',
        change_password: 'Change password',
        old_password: 'Current password',
        old_password_is_required: 'Current password is required',
        new_password: 'New password',
        new_password_again: 'New password again',
        new_password_is_required: 'New password is required',
        new_password_again_is_required: 'New password again is required',
        password_complexity_desc: 'Password must be at least 8 characters long and must contain at least one lowercase letter, one uppercase letter and one number',
        error_old_password_no_match: 'The current password is not correct, please try again',
        password_changed: 'Password changed successfully',
        profile_events: 'Events',
        profile_communications: 'Communications',
        access_scope_mitigate: 'Access scope (Mitigate)',
        access_scope_recash: 'Access scope (RECASH)',
        access_scope_profound: 'Access scope (Profound)',
        at_least_once_template_required: 'At least one access scope must be specificed',
        dashboard_user: 'Dashboard user',
        other: 'Other',
        last_login: 'Last login date',
        company: 'Company',
        business_group: 'Company group',
        business_manager: 'Manager',
        access_groups: 'Access group(s)',
        access_scopes: 'Access scope(s)',
        update: 'Update',
        export_users: 'Export users',
        score_components: 'Score components',
        score_components_desc: 'Used score components to calculate the final score:',
        score_component_banking_api: 'Banking Data',
        score_component_fraud: 'Fraud Data',
        score_component_socio: 'Socio-economic Data',
        score_component_company: 'Company Data',
        client_refusal_status: 'Consent rejection reason',
        refusal_status_bank_not_available: 'Bank was not available',
        refusal_status_consent_refused: 'Client did not want to give consent',
        refusal_status_other: 'Other',
        export_client_company_data: 'Export company data',
        client_company_score_data: 'Company score data',
        company_short_name: 'Short name',
        company_long_name: 'Long name',
        company_taxnumber: 'Tax number',
        company_year_info: 'Financial data year',
        company_totalscore: 'Total score',
        company_ophistoryscore: 'Operational history score',
        company_profscore: 'Profitability score',
        company_cashliqscore: 'Cash liquidity score',
        company_debtscore: 'Debt ratio score',
        company_exscore: 'Executions score',
        company_revscore: 'Revenue score',
        client_personal_risk_score_data: 'Personal risk score data',
        personal_risk_total_score: 'Total score',
        personal_risk_identity_score: 'Identity score',
        personal_risk_transaction_score: 'Transaction score',
        client_socio_score_data: 'Socio score data',
        socio_score_totalscore: 'Total score',
        socio_score_address_score: 'Address score',
        socio_score_age_score: 'Age score',
        socio_score_income_score: 'Income score',
        socio_score_balance_score: 'Balance score',
        socio_score_family_score: 'Family score',
        socio_score_education_score: 'Education score',
        //Scopes
        product_mitigate: 'Mitigate',
        product_profound: 'Profound',
        product_recash: 'Recash',
        scope_mitigate_admin: 'Mitigate Administrator',
        scope_mitigate_manage_client: 'Manage clients',
        scope_mitigate_manage_all_clients: 'Manage all clients',
        scope_mitigate_manage_own_clients: 'Manage own clients',
        scope_mitigate_view_own_client_data: 'View own client data',
        scope_mitigate_view_all_client_data: 'View all client data',
        scope_mitigate_view_own_client_data_details: 'View own client data details',
        scope_mitigate_view_all_client_data_details: 'View all client data details',
        scope_mitigate_edit_client: 'Edit client data',
        scope_mitigate_edit_all_clients: 'Edit all client data',
        scope_mitigate_edit_own_clients: 'Edit own client data',
        scope_mitigate_change_status_client: 'Change client status',
        scope_mitigate_delete_client: 'Delete clients',
        scope_mitigate_delete_all_clients: 'Delete all clients',
        scope_mitigate_delete_own_clients: 'Delete own clients',
        scope_mitigate_export_clients: 'Export client data',
        scope_mitigate_can_approve_client: "Can set client status to Approved",
        scope_mitigate_can_reject_client: "Can set client status to Rejected",
        scope_mitigate_can_finish_client: "Can set client status to Finished",
        scope_mitigate_can_close_client: "Can set client status to Closed",
        scope_mitigate_assessment_view: 'View assessment data',
        scope_mitigate_assessment_history: 'See assessment history',
        scope_mitigate_bank_accounts: 'See bank accounts',
        scope_mitigate_bank_account_details: 'See bank account details',
        scope_mitigate_transactions: 'See transaction list',
        scope_mitigate_scans: 'View manual scans',
        scope_mitigate_manage_scans: 'Manage manual scans',
        scope_mitigate_bank_access: 'Manage bank consents',
        scope_mitigate_monitoring: 'View client monitoring',
        scope_mitigate_manage_monitoring: 'Manage client monitoring',
        scope_mitigate_communications: 'See communications data',
        scope_mitigate_events: 'See events',
        scope_mitigate_logs: 'See system logs',
        scope_mitigate_request_data: 'Request assessment data',
        scope_mitigate_assessment_view_all: 'View all assessment data',
        scope_mitigate_assessment_view_summary_stats: 'View summary statistics',
        scope_mitigate_assessment_view_income: 'View income data',
        scope_mitigate_assessment_view_fraud: 'View fraud data',
        scope_mitigate_assessment_view_accounts: 'View assessment accounts',
        scope_mitigate_assessment_view_stats: 'View assessment detailed statistics',
        scope_mitigate_assessment_view_transactions: 'View assessment transactions',
        scope_mitigate_assessment_view_certificate: 'View certificate data',
        scope_mitigate_assessment_view_export: 'Assessment export data',
        scope_mitigate_score_details: 'See scoring details',
        scope_mitigate_manage_users: 'Manage users',
        scope_mitigate_delete_users: 'Delete users',
        scope_mitigate_manage_all_users: 'Manage all users',
        scope_mitigate_delete_all_users: 'Delete all users',
        scope_mitigate_manage_own_users: 'Manage own users',
        scope_mitigate_delete_own_users: 'Delete own users',
        scope_mitigate_manage_groups: 'Manage groups',
        scope_mitigate_delete_groups: 'Delete groups',
        account_holder_name_check_title: 'Account holder name check',
        account_holder_name_check_desc: 'The name of the account holder from the bank matches the client name',
        account_holder_name_check_desc_nomatch: 'The name of the account holder from the bank DOES NOT match the client name',
        account_holder_name_check_desc_unknown: 'The account holder name and the client name match could not be determined',

        country_hungary: 'Hungary',
        country_czech: 'Czech Republic',
        country_croatia: 'Croatia',
        country_noneu: 'Outside EU'
      }
}