import { FileSearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import MainCard from "components/MainCard";
import TransactionDetails from "pages/clients/components/TransactionDetails";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default({id, title, model, transactions}) => {
    const [t, i18n] = useTranslation();
    const [openDetails, setOpenDetails] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const showDetails = (item) => {
        setSelectedTransaction(item);
        setOpenDetails(true);
    }
    const hideDetails = () => {
        setOpenDetails(false);
    }
    return(
        <>
        <MainCard title={title} contentSX={{padding: 0}}>
            <Table>
                <TableHead>
                    <TableRow>
                    <TableCell sx={{width: '15%'}}>{t('id')}</TableCell>
                    <TableCell>{t('information')}</TableCell>
                    <TableCell sx={{width: '10%'}}>{t('type')}</TableCell>
                    <TableCell sx={{width: '10%'}}>{t('date')}</TableCell>
                    <TableCell sx={{width: '10%'}}>{t('amount')}</TableCell>
                    <TableCell sx={{width: '5%'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions?.map(i => {
                        return (
                            <TableRow key={id+'_transaction_'+i.id}>
                                <TableCell>{model.dataOrEmpty(i.id)}</TableCell>
                                <TableCell>{model.dataOrEmpty(i.unstructuredInformation)}</TableCell>
                                <TableCell>{t('transaction_type_'+i.type.toLowerCase())}</TableCell>
                                <TableCell>{model.formatShortDate(i.transactionDate)}</TableCell>
                                <TableCell>{model.formatCurrency(Math.abs(i.amount), i.currency)}</TableCell>
                                <TableCell>
                                    <IconButton color="secondary" onClick={() => {showDetails(i)}}>
                                        <Tooltip title={t('information')}><FileSearchOutlined /></Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    })
                    }
                    {transactions && transactions.length == 0 && 
                    <TableRow key={id+'_transaction_empty'}>
                        <TableCell colSpan={4}>{t('no_data')}</TableCell>
                    </TableRow>
                    }
                </TableBody>
            </Table>
        </MainCard>
        <TransactionDetails model={model} open={openDetails} onClose={hideDetails} transaction={selectedTransaction} />
        </> 
    );
}