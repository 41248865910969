import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table
} from '@mui/material';
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";


export default({model, chartData}) => {
    const [t, i18n] = useTranslation();
    const socioScoreData = chartData?.data?.assessmentData?.socioData;
    return(
        <MainCard title={t('client_socio_score_data')} contentSX={{padding: 0}}>
            <Table sx={{ p: 0 }}>
                <TableBody>
                    <TableRow key={'socio_score_totalscore'}>
                        <TableCell sx={{width: '20%'}}><Typography style={{fontWeight: 'bold'}}>{t('socio_score_totalscore')}</Typography></TableCell>
                        <TableCell>{socioScoreData?.totalScore}</TableCell>
                    </TableRow>
                    <TableRow key={'socio_score_address_score'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('socio_score_address_score')}</Typography></TableCell>
                        <TableCell>{socioScoreData?.addressScore}</TableCell>
                    </TableRow>
                    <TableRow key={'socio_score_age_score'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('socio_score_age_score')}</Typography></TableCell>
                        <TableCell>{socioScoreData?.ageScore}</TableCell>
                    </TableRow>
                    <TableRow key={'socio_score_income_score'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('socio_score_income_score')}</Typography></TableCell>
                        <TableCell>{socioScoreData?.incomeScore}</TableCell>
                    </TableRow>
                    <TableRow key={'socio_score_balance_score'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('socio_score_balance_score')}</Typography></TableCell>
                        <TableCell>{socioScoreData?.balanceScore}</TableCell>
                    </TableRow>
                    <TableRow key={'socio_score_family_score'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('socio_score_family_score')}</Typography></TableCell>
                        <TableCell>{socioScoreData?.familyStatusScore}</TableCell>
                    </TableRow>
                    <TableRow key={'socio_score_education_score'}>
                        <TableCell><Typography style={{fontWeight: 'bold'}}>{t('socio_score_education_score')}</Typography></TableCell>
                        <TableCell>{socioScoreData?.educationLevelScore}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </MainCard>
    );
}